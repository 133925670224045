<template>
    <b-row class="match-height">
        <b-col cols="12">
            <b-jumbotron id="jumDts">
                <div class="container-fluid">
                    <div class="row justify-content-between" v-if="this.name.length > 0">
                        <main class="col-sm-12 text-center pb-2">
                            <h1>Trip of {{ name }}</h1>
                        </main>
                        <main class="col-sm-6">
                            <div>
                                <span>Product: </span>
                                <span>{{ product_name }}</span>
                            </div>
                            <div>
                                <span>Amount: </span>
                                <span>{{ full_amount }}</span>
                            </div>
                            <div>
                                <span>Residence: </span> 
                                <span>{{ getCountryName(residence_literal) }}</span>
                            </div>
                            <div>
                                <span>Destination: </span> 
                                <span>{{ getCountryName(destination_literal) }}</span>
                            </div>
                        </main>
                        <main class="col-sm-6">
                            <div>
                                <span>Trip Duration: </span> 
                                <span>{{ convertToDateFormat(start_date) }}</span> - 
                                <span>{{ convertToDateFormat(end_date) }}</span>
                                <b-button class="ml-1" size="sm" variant="outline-secondary" @click="openDatesModal"
                                v-if="ls_role=='admin'" 
                                >Edit</b-button>
                                <!-- Modal -->
                                <b-modal v-model="showDatesModal" title="Edit Trip Duration" :hide-footer="true">
                                    <b-form @submit.prevent="saveDateChanges">
                                        <b-form-group label="Start Date (DD/MM/YYYY)">
                                            <b-form-input type="date" v-model="formattedStartDate" :max="formattedEndDate" @change="updateMinEndDate"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="End Date (DD/MM/YYYY)">
                                            <b-form-input type="date" v-model="formattedEndDate" :min="formattedStartDate"></b-form-input>
                                        </b-form-group>
                                        <div class="d-flex justify-content-between">
                                            <b-button type="submit" variant="primary">Save Changes</b-button>
                                            <b-button type="button" variant="secondary" @click="cancelDateChanges">Cancel</b-button>
                                        </div>
                                    </b-form>
                                </b-modal>
                            </div>
                            <div>
                                <span>Date of Issue: </span>
                                <span>{{ issueDate }}</span>
                            </div>
                            <div>
                                <span>State: </span>
                                <span>{{ state }}</span>
                            </div>
                            <div>
                                <span>Email: </span>
                                <span>{{ email }}</span>
                            </div>
                        </main>
                    </div>
                    <div class="row" v-else>
                        <div class="col-sm-12 text-center">
                            <b-spinner variant="dark"></b-spinner>
                        </div>
                    </div>
                </div>
            </b-jumbotron>
        </b-col>

        <b-col
        lg="6"
        md="6"
        sm="12"
        >
            <b-card title="Client information">
                <b-card-body class="d-flex align-items-center justify-content-center" v-if="!customer_dts">
                    <b-spinner variant="dark"></b-spinner>
                </b-card-body>
                <b-card-body v-else>
                    <b-card-text class="d-block mt-1">
                        Full Name: {{ name }}
                    </b-card-text>
                    <b-card-text class="d-block mt-1">
                        Telephone: {{ telephone }}
                    </b-card-text>
                    <b-card-text class="d-block mt-1">
                        Address: {{ address }}
                    </b-card-text>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        md="6"
        sm="12"
        >
            <b-card title="Partner information">
                <b-card-body class="d-flex align-items-center justify-content-center" v-if="!customer_dts">
                    <b-spinner variant="dark"></b-spinner>
                </b-card-body>
                <b-card-body v-else>
                    <b-card-text class="d-block mt-1">
                        Company Name: {{ customer_dts.company_name }}
                    </b-card-text>
                    <b-card-text class="d-block mt-1">
                        Email: {{ customer_dts.email }}
                    </b-card-text>
                    <b-card-text class="d-block mt-1">
                        Active: {{ customer_dts.active }}
                    </b-card-text>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { cookie, token_id, ls_role, countrysFrom, countrysTo } from '@/products';
import { BRow, BCol, BCard, BJumbotron, BTable, BButton, BCardText, BCardTitle, BCardBody, BSpinner, BForm, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
    props: ['name', 'product_name', 'full_amount', 'residence_literal', 'destination_literal', 'state', 'email', 'end_date', 'start_date', 'issueDate', 'customers', 'customer_dts', 'telephone', 'address'],
    components: {
        BRow,
        BCol,
        BCard,
        BJumbotron,
        BTable,
        BButton,
        BCardText,
        BCardTitle,
        BCardBody,
        BSpinner,
        BForm,
        BFormGroup,
        BFormInput
    },
    data() {
        return {
            cookie, 
            token_id, 
            ls_role,
            countrysFrom,
            countrysTo,
            editedStartDate: "",
            editedEndDate: "",
            showDatesModal: false,
        }
    },
    methods: {
        getCountryName(residence_literal) {
            const country = countrysFrom.find(country => country.value === String(residence_literal));
            return country ? country.text : '-'; // Return the text of the country or a fallback message
        },
        convertToDateFormat(date) {
            const parts = date.split('-'); // YYYY-MM-DD
            if (parts.length === 3) {
                return `${parts[2]}/${parts[1]}/${parts[0]}`;  // Convert to DD/MM/YYYY
            }
            return date;
        },
        openDatesModal() {
            this.showDatesModal = true;
            this.editedStartDate = this.start_date;
            this.editedEndDate = this.end_date; 
        },
        updateMinEndDate() {
            if (this.formattedEndDate < this.formattedStartDate) {
                this.formattedEndDate = this.formattedStartDate; // Reset if invalid
            }
        },
        saveDateChanges() {
            // Convert the dates back to DD/MM/YYYY format for backend
            const startDate = this.convertToDateFormat(this.formattedStartDate);  // Convert to DD/MM/YYYY format
            const endDate = this.convertToDateFormat(this.formattedEndDate);      // Convert to DD/MM/YYYY format

            const url= {
                prod: 'https://api-quote.safetravel.today/v2/app/update_dates'
            };
            const data = {
                token: this.token_id,
                jwt: this.cookie,
                quote_id: Number(this.customers[0].quote_id),
                start_date: startDate,
                end_date: endDate
            }
            // Update
            fetch(url.prod, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    // Emit updated dates to parent component in DD/MM/YYYY format for backend
                    this.$emit('update:start_date', startDate);
                    this.$emit('update:end_date', endDate);
                } else {
                    alert("Update failed: " + result.message);
                }
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {this.showDatesModal = false;});
        },
        cancelDateChanges() {
            // Reset to original dates
            this.formattedStartDate = this.start_date;
            this.formattedEndDate = this.end_date;
            this.showDatesModal = false; // Close the modal
        }
    },
    computed: {
        // Show date in YYYY-MM-DD for the modal and UI
        formattedStartDate: {
            get() {
                if (!this.editedStartDate) return "";
                const parts = this.editedStartDate.split("/"); // Assuming input date is in DD/MM/YYYY format
                if (parts.length === 3) {
                    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert to YYYY-MM-DD
                }
                return "";
            },
            set(newValue) {
                if (!newValue) return;
                let [year, month, day] = newValue.split("-"); // Convert YYYY-MM-DD to DD/MM/YYYY
                this.editedStartDate = `${day}/${month}/${year}`;
            }
        },

        // Show date in YYYY-MM-DD for the modal and UI
        formattedEndDate: {
            get() {
                if (!this.editedEndDate) return "";
                const parts = this.editedEndDate.split("/"); // Assuming input date is in DD/MM/YYYY format
                if (parts.length === 3) {
                    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert to YYYY-MM-DD
                }
                return "";
            },
            set(newValue) {
                if (!newValue) return;
                let [year, month, day] = newValue.split("-"); // Convert YYYY-MM-DD to DD/MM/YYYY
                this.editedEndDate = `${day}/${month}/${year}`;
            }
        }
    }
}
</script>
